@font-face {
  font-family: "TheDoctor";
  src: local("TheDoctor"), url(./fonts/TheDoctor.otf) format("opentype");
}

@font-face {
  font-family: "MessinaSerif-Regular";
  src: local("MessinaSerif-Regular"),
    url(./fonts/MessinaSerif-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MessinaSerif-RegularItalic";
  src: local("MessinaSerif-RegularItalic"),
    url(./fonts/MessinaSerif-RegularItalic.otf) format("opentype");
}

@font-face {
  font-family: "MessinaSans-Regular";
  src: local("MessinaSans-Regular"),
    url(./fonts/MessinaSans-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MessinaSans-SemiBold";
  src: local("MessinaSans-SemiBold"),
    url(./fonts/MessinaSans-SemiBold.otf) format("opentype");
}
